import React, { useLayoutEffect } from "react"
import { useStaticQuery, graphql } from "gatsby"

import Layout from "../../layout"
import SEO from "../../seo"

import { Button } from "../../anti/buttons/buttons"
import { Breadcrumb } from "../../anti/breadcrumb/breadcrumb"
import { Cover, CoverContent } from "../../anti/cover/cover"
import { Card } from "../../anti/card/card"
import { CardSlider } from "../../anti/card-slider/card-slider"
import { Container, Section } from "../../anti/grid/grid"
import { NavBottom } from "../../anti/nav-bottom/nav-bottom"
import { HeadingLink } from "../../anti/type/type"

import "@glidejs/glide/dist/css/glide.core.min.css"
import "@glidejs/glide/dist/css/glide.theme.min.css"

const DevelopmentCategoryPage = () => {
  const data = useStaticQuery(graphql`
    query {
      imgCover: file(relativePath: { eq: "sample/bg_rectangle-dark-2.jpg" }) {
        id
        name
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  // Modify navbar style
  useLayoutEffect(() => {
    const navbar = document.querySelector(".navbar")
    navbar.classList.remove("transparent")
    navbar.classList.replace("dark", "light")
  }, [])

  // const wordpress = data.wordPress.projectBy && data.wordPress.projectBy.blocks;
  return (
    <Layout>
      <SEO title="Details" />

      {/* On ALL details pages */}
      <Cover
        variant="basic"
        theme="dark"
        breadcrumb={<Breadcrumb />}
        img={data.imgCover.childImageSharp.fluid}
        imgHeight="h-300px h-md-400px"
        imgOverlay="40"
        label="Welcome to"
        title="Southgate"
      />

      {/* On ALL details pages */}
      <Section className="bg-light pb-0">
        <Container className="">
          <div className="row">
            <div className="col-lg-6 col-xl-5">
              {/* <h2 className="h2">
                {wordpress[1].innerBlocks[0].attributes.content}
              </h2> */}
            </div>
            <div className="col-lg-6 col-xl-7">
              <p>{/* {wordpress[1].innerBlocks[1].attributes.content} */}</p>
              <p>{/* {wordpress[1].innerBlocks[2].attributes.content} */}</p>
              {/* On FUTURE DEVELOPMENT details pages only */}
              <div className="mt-5">
                <p class="h4 mb-3 text-gray-600">Estimated Completion Date</p>
                <p>March 2020</p>
              </div>
              {/* On COMMERCIAL details pages only */}
              <Button variant="link" to="#">
                Visit Website
              </Button>
            </div>
          </div>
        </Container>
      </Section>

      {/* On COMMERCIAL details pages only */}
      <Section>
        <Container>
          <HeadingLink>
            <h2 className="h3">Tenants</h2>
            <Button variant="link">See All</Button>
          </HeadingLink>
          <CardSlider
            showInitial={6}
            showLgDown={5}
            showMdDown={4}
            showSmDown={3.5}
          >
            {/* prettier-ignore */}
            <Card variant="boxless" img={data.imgCover.childImageSharp.fluid} imgHeight="h-ratio-1-1"/>
            {/* prettier-ignore */}
            <Card variant="boxless" img={data.imgCover.childImageSharp.fluid} imgHeight="h-ratio-1-1"/>
            {/* prettier-ignore */}
            <Card variant="boxless" img={data.imgCover.childImageSharp.fluid} imgHeight="h-ratio-1-1"/>
            {/* prettier-ignore */}
            <Card variant="boxless" img={data.imgCover.childImageSharp.fluid} imgHeight="h-ratio-1-1"/>
            {/* prettier-ignore */}
            <Card variant="boxless" img={data.imgCover.childImageSharp.fluid} imgHeight="h-ratio-1-1"/>
            {/* prettier-ignore */}
            <Card variant="boxless" img={data.imgCover.childImageSharp.fluid} imgHeight="h-ratio-1-1"/>
            {/* prettier-ignore */}
            <Card variant="boxless" img={data.imgCover.childImageSharp.fluid} imgHeight="h-ratio-1-1"/>
            {/* prettier-ignore */}
            <Card variant="boxless" img={data.imgCover.childImageSharp.fluid} imgHeight="h-ratio-1-1"/>
            {/* prettier-ignore */}
            <Card variant="boxless" img={data.imgCover.childImageSharp.fluid} imgHeight="h-ratio-1-1"/>
            {/* prettier-ignore */}
            <Card variant="boxless" img={data.imgCover.childImageSharp.fluid} imgHeight="h-ratio-1-1"/>
          </CardSlider>
        </Container>
      </Section>

      {/* On RESIDENCE, OFFICE details pages */}
      <Section className="bg-light">
        <Container>
          <Card
            img={data.imgCover.childImageSharp.fluid}
            imgHeight="h-ratio-2-1"
          />
        </Container>
      </Section>

      {/* On ALL details pages */}
      <Section className="bg-gray-400 h-300px h-md-400px content-center">
        Map here
      </Section>
      <Section>
        <Container>
          <h2 className="mb-4">
            {/* {wordpress[4].innerBlocks[0].attributes.content} */}
          </h2>
          <div className="row row-3">
            <div className="col-md-6">
              {/* <Feature
                title={wordpress[4].innerBlocks[1].attributes.content}
                text={wordpress[4].innerBlocks[2].attributes.content}
              /> */}
            </div>
            <div className="col-md-6">
              {/* <Feature
               title={wordpress[4].innerBlocks[3].attributes.content}
               text={wordpress[4].innerBlocks[4].attributes.content}
              /> */}
            </div>
          </div>
        </Container>
      </Section>

      {/* On RESIDENCE, OFFICE, FUTURE DEVELOPMENT details pages */}
      <Section className="bg-light">
        <Container>
          <div className="row mb-n2">
            <div className="col-12 col-md-4 col-lg">
              {/* <h3>{wordpress[5].innerBlocks[0].attributes.content}</h3> */}
            </div>
            {/* {wordpress[10].acf.list.map((value, index)=>{
              return (
              <div className="col-12 col-md-4 col-lg">
                <Feature
                  img={data.imgCover.childImageSharp.fluid}
                  title={value.title}
                  titleClassName="mb-0 mt-sm-down-1"
                />
              </div>
              )
            })} */}
          </div>
        </Container>
      </Section>

      {/* On RESIDENCE, COMMERCIAL, OFFICE, FUTURE DEVELOPMENT details pages */}
      <Section>
        <Container>
          <h2 className="h3">Nearby</h2>
          <CardSlider showInitial={3} showLgDown={3} showMdDown={2}>
            <Card
              variant="overlay"
              title="AEON Mall"
              titleClassName="h5"
              text="Shop & Dine"
              img={data.imgCover.childImageSharp.fluid}
              imgHeight="h-ratio-1-1"
            />
            <Card
              variant="overlay"
              title="BSD Green Office"
              titleClassName="h5"
              text="Office"
              img={data.imgCover.childImageSharp.fluid}
              imgHeight="h-ratio-1-1"
            />
            <Card
              variant="overlay"
              title="The Breeze"
              titleClassName="h5"
              text="Shop & Dine"
              img={data.imgCover.childImageSharp.fluid}
              imgHeight="h-ratio-1-1"
            />
            <Card
              variant="overlay"
              title="AEON Mall"
              titleClassName="h5"
              text="Shop & Dine"
              img={data.imgCover.childImageSharp.fluid}
              imgHeight="h-ratio-1-1"
            />
            <Card
              variant="overlay"
              title="AEON Mall"
              titleClassName="h5"
              text="Shop & Dine"
              img={data.imgCover.childImageSharp.fluid}
              imgHeight="h-ratio-1-1"
            />
            <Card
              variant="overlay"
              title="AEON Mall"
              text="Shop & Dine"
              img={data.imgCover.childImageSharp.fluid}
              imgHeight="h-ratio-1-1"
            />
          </CardSlider>
        </Container>
      </Section>

      {/* On RESIDENCE details pages only */}
      <CoverContent
        theme="dark"
        img={data.imgCover.childImageSharp.fluid}
        imgOverlay="20"
        className="mb-n2"
      >
        <h2 className="h2">Marketing Gallery</h2>
        <p>
          Visit our Marketing Gallery to learn more about BSD City and all the
          opportunities in it!
        </p>
        <Button variant="link" to="#">
          Get Direction
        </Button>
      </CoverContent>

      {/* On RESIDENCE, COMMERCIAL, OFFICE details pages */}
      <NavBottom
        prevTitle="Q-Big"
        prevImg={data.imgCover.childImageSharp.fluid}
        prevLink="/"
        nextTitle="ITC BSD"
        nextImg={data.imgCover.childImageSharp.fluid}
        nextLink="/"
      />
    </Layout>
  )
}

export default DevelopmentCategoryPage
