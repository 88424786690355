import React from "react"
import PropTypes from "prop-types"

import { Card } from "../card/card"

export const NavBottom = ({
  data,
  slug,
  prevTitle,
  prevImg,
  prevLink,
  nextTitle,
  nextImg,
  nextLink,
  className,
  lang,
}) => {
  const lastItem = data && data[data.length - 1]
  return (
    <div className={`nav-bottom ${className}`}>
      <div className="row row-0">
        <div className="col">
          <Card
            variant="overlay"
            imgOverlay="40"
            label={lang == "EN" ? "Previous" : "Sebelumnya"}
            title={prevTitle}
            img={prevImg}
            imgHeight="h-150px"
            link={prevLink}
            className="nav-bottom-item prev"
          />
        </div>
        <div className="col">
          {lastItem && lastItem.slug == slug ? (
            <Card
              variant="overlay"
              imgOverlay="40"
              label={lang == "EN" ? "Next" : "Selanjutnya"}
              title={data[0]?.blocks[0]?.coverMain?.title}
              img={data[0]?.blocks[0]?.coverMain?.img?.mediaItemUrl}
              imgHeight="h-150px"
              link={`${nextLink}` + `${data[0].slug}`}
              className="nav-bottom-item next"
            />
          ) : (
            <Card
              variant="overlay"
              imgOverlay="40"
              label={lang == "EN" ? "Next" : "Selanjutnya"}
              title={nextTitle}
              img={nextImg}
              imgHeight="h-150px"
              link={nextLink}
              className="nav-bottom-item next"
            />
          )}
        </div>
      </div>
    </div>
  )
}

NavBottom.propTypes = {
  prevTitle: PropTypes.string, // Set previous title
  prevImg: PropTypes.any, // Set previous image
  prevLink: PropTypes.string, // Set previous link / href
  nextTitle: PropTypes.string, // Set next title
  nextImg: PropTypes.any, // Set next image
  nextLink: PropTypes.string, // Set next link / href
  className: PropTypes.string, // Set cover optional classes
  children: PropTypes.any,
}

NavBottom.defaultProps = {
  prevLink: "",
  nextLink: "",
  className: "",
}
